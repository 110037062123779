<template>
	<div class="content">
		<v-row no-gutters class="justify-center justify-space-between flex-md-nowrap">
			<v-col cols="12">
				<v-row no-gutters>
					<v-col cols="12" md="6">
						<h1 class="text-md-h1 text-h5 font-weight-black">
							{{ firstWord }}
							<span
								v-if="otherWords"
								class="subtitle mainColor--text"
							>
								{{ otherWords }}
							</span>
						</h1>
					</v-col>
					<v-col cols="12" md="10">
						<p
							class="vue-editor text-md-subtitle-1 text-body-2 grayText--text mt-4 mt-md-9"
							v-html="state.currentPosition.location"
						/>
					</v-col>
					<v-col cols="12" class="d-flex">
						<TopBtn />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import TopBtn from '@/components/current-position/current-position-buttons/TopBtn'

export default {
	name: 'PreviewSection',
	components: {
		TopBtn
	},
	computed: {
		titleParts () {
			if (!this.state.currentPosition.title) return
			return this.state.currentPosition.title.split(' ')
		},
		firstWord () {
			if (!this.titleParts) return
			return this.titleParts[0]
		},
		otherWords () {
			if (!this.titleParts || this.titleParts.length === 1) return
			else return this.titleParts.slice(1).join(' ')

		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	margin-top: 115px;
	margin-bottom: 150px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.content {
		margin-top: 36px;
		margin-bottom: 60px;
	}
}
</style>

import PreviewSection from '@/components/current-position/PreviewSection.vue'
import PositionContent from '@/components/current-position/PositionContent.vue'
import LastBtn from '@/components/current-position/current-position-buttons/LastBtn'
import ApiOpenPositions from '@/api/open-positions'

export default {
	name: 'CurrentPosition',
	components: {
		PreviewSection,
		PositionContent,
		LastBtn
	},
	metaInfo () {
		return {
			title: this.metaTitle,
			meta: [{
				name: 'description',
				content: this.metaDescription,
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: this.metaTitle,
			},
			{
				name: 'og:description',
				content: this.metaDescription,
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: this.metaTitle,
			},
			{
				name: 'twitter:description',
				content: this.metaDescription,
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
		],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
	data: () => ({
		isPageNotFound: false,
	}),
	mounted () {
		ApiOpenPositions.getPosition(this, this.$route.params.slug)
	},
	computed: {
		metaTitle () {
			return this.state.currentPosition.title ? this.state.currentPosition.title : 'Position'
		},
		metaDescription () {
			if (!this.state.currentPosition.title) return
			return 'Topic description about "' + this.state.currentPosition.title + '" position'
		}
	},
	watch: {
		isPageNotFound (value) {
			if (value) {
				this.$router.push({
					name: 'Page404'
				})
			}
		}
	}
}

import ApiBase from '@/api/base'

class ApiOpenPositions {
	static getAll (self) {
		self.http('get', `${ApiBase.baseApiUrl()}/open-positions`)
			.then(response => {
				ApiBase.setToStore(null, 'setOpenPositions', response.data)
			})
	}
	static getPosition (self, slug) {
		self.http('get', `${ApiBase.baseApiUrl()}/open-positions/${slug}`)
			.then(response => {
				ApiBase.setToStore(null, 'setCurrentPosition', response.data)
			})
			.catch(() => self.isPageNotFound = true)
	}
}

export default ApiOpenPositions

import ApiApplyPositionCreateRequest from '@/api/apply-position-create'
import CloseModal from '@/components/btns/CloseModal'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
	name: 'FormComponent',
	components: {
		CloseModal,
		VuePhoneNumberInput
	},
	data: () => ({
		phone: '',
		message: '',
		errors: {},
		isFormSent: false,
		valid: false,
		form: {
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			resume_files: [],
			linked_in: '',
			job_link: window.location.href
		},
		fileWarn: '',
		sending: false,
		isValidPhoneNumber: false
	}),
	computed: {
		firstNameRules () {
			return [
				v => v.length >= 2 || this.trans('apply_position_form_first_name_required')
			]
		},
		lastNameRules () {
			return [
				v => v.length >= 2 || this.trans('apply_position_form_last_name_required')
			]
		},
		emailRules () {
			const emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
			return [
				v => !!v || this.trans('apply_position_form_email_required'),
				v => emailFilter.test(v) ||
					this.trans('apply_position_form_email_must_valid'),
				v => !(/[а-яА-ЯЁё]/.test(v)) || this.trans('apply_position_form_email_must_not_cyrillic')
			]
		},
		// urlRules () {
		// 	const urlFilter = /^(ftp|http|https):\/\/[^ "]+$/
		// 	return [
		// 		v => urlFilter.test(v) || this.trans('apply_position_form_url_must_valid'),
		// 	]
		// },
		fileRules () {
			return [
				v => !!v || this.trans('apply_position_form_file_required'),
				v => (!!v && (v.size / 1024 / 1024 ) <= 4) || this.trans('apply_position_form_file_warn'),
			]
		},
		phoneInputTranslations () {
			return {
				countrySelectorLabel: this.cleanTextFromTags(this.trans('apply_position_form_phone_country_code')),
				phoneNumberLabel: this.cleanTextFromTags(this.trans('apply_position_form_form_phone_field') + '*'),
				example: this.cleanTextFromTags(this.trans('apply_position_form_phone_number_example') + ': ')
			}
		}
	},
	methods: {
		setPhoneNumber (data) {
			this.isValidPhoneNumber = data.isValid
			if (data.isValid) {
				this.form.phone = data.formatInternational
			}
		},
		submit () {
			this.sending = true
			if (!this.$refs.form.validate()) return

			let formData = new FormData
			this.addEmails()
			for(let field in this.form) {
        if (field === 'resume_files') {
          this.form[field].map(file => {
            formData.append('resume_files', file)
          })
        } else {
          formData.append(field, this.form[field])
        }
			}
			ApiApplyPositionCreateRequest.create(this, formData).then(response => {
				this.message = this.trans(response.data.message)
				this.sending = false
				this.isFormSent = true
			}).catch(err => {
				this.showErrors(err.response.data.errors)
			})
		},
		handleFileInput (event) {
			if (!event) return
      let file = event
      this.clearError('resume_files')
			this.form.resume_files = []
			this.form.resume_files.push(file)
    },
		addEmails () {
			if (!this.state.emails && !this.state.emails.length) return
			let email = this.state.emails.find(item => item.key === 'apply_position')
			this.form.from = email.email_from
			this.form.admin_email = email.email_to
		},
		close () {
			this.form = {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				resume_files: [],
				linked_in: '',
			}
			this.isFormSent = false
			this.$root.$emit('closeModalWindow')
		},
		showErrors (errors) {
			this.errors = errors
		},
		clearError (field) {
			this.errors[field] = ''
		}
	}
}

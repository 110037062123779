<template>
<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="vue-editor text-md-h2 text-subtitle-1 font-weight-black mb-5 mb-md-9"
					v-html="trans('current_position_about')"
				/>
			</v-col>
			<v-col cols="12">
				<div
					v-if="state.currentPosition.description"
					v-html="state.currentPosition.description"
					class="vue-editor text-content sub-title text-md-body-1 text-body-2 grayText--text mr-md-11"
				/>
			</v-col>
		</v-row>
</div>
</template>

<script>
export default {
	name: 'PositionContent'
}
</script>

<style lang="scss" scoped>
.text-content::v-deep() {
	h3 {
		font-size: 40px;
		line-height: 50px;
		font-weight: bold;
		color: var(--v-black-base);
		margin-top: 75px;
		margin-bottom: 35px;
	}
	li {
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 0px;
		}
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.text-content::v-deep() {
		h3 {
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 20px;
			margin-top: 60px;
		}
	}
}
</style>
